@tailwind base;
@tailwind components;
@tailwind utilities;

h1, h2, h3 {
  font-family: 'Noto Serif', serif;
}

h4, h5, h6, body {
  font-family: 'Montserrat', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
